import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './helpMenu.css'
import CSSModules from 'react-css-modules'
import Button from '../../../../components/button/Button'
import HelpPopup from './HelpPopup';
import Tippp from '../../../../components/tip/Tippp';
import {userConstants} from '../../../../services/constants/';
import {newDemo} from '../../../../redux/document/actions';
import { transS } from '../../../../services/helpers/lang';

const HelpMenu = ({showHelp, helpClose}) => {
  const {user, firstTime} = useSelector(state => state.userReducer);
  const [isMenuOpen, _setIsMenuOpen] = useState(showHelp);
  const isMenuOpenRef = useRef(isMenuOpen);
  const dispatch = useDispatch();
  const setIsMenuOpen = data => {
    isMenuOpenRef.current = data;
    _setIsMenuOpen(data);
  };
  const [popupOpen, setPopupOpen] = useState(false);
  const [isHelpLooked, setIsHelpLooked] = useState(false);
  const refWrapper = useRef(null);  
  
  
  useEffect(() => {
    const helpLooked = localStorage.getItem('helpLooked')
    if (helpLooked) 
      setIsHelpLooked(helpLooked);
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, []);

  const handleClickOutside = e => {
    if (!refWrapper?.current) return;
    if ((isMenuOpenRef || showHelp) 
       && e.target !== refWrapper.current && !refWrapper.current.contains(e.target)) {
         menuOpenHandler(false);
    }
  };

  const menuOpenHandler = (val) => {
    if (helpClose) helpClose();
    
    if (!isHelpLooked && (val !== null && val || !isMenuOpen)) {
      localStorage.setItem('helpLooked', '1');
      setIsHelpLooked(true);
    }
    
    setIsMenuOpen(val !== null ? val : !isMenuOpen)
  };

  const btnClass = 'helpBtn' + (isHelpLooked ? '' : ' helpBtnBlue'),
    _absolute = helpClose ? ' _absolute' : '';
    
  return popupOpen ? (
    <HelpPopup popupOpen={popupOpen} popupOpenHandler={setPopupOpen} />
    ) : (
        <div ref={refWrapper} styleName={'wrapper' + _absolute} >
           {!helpClose &&
              <div className={btnClass} onClick={() => menuOpenHandler(true)}>?</div>}

           {!!(isMenuOpen || showHelp) ? 
           <div styleName={'menu' + _absolute}>
              <div className='h2' style={{lineHeight: 'normal', marginBottom: '33px'}}>{transS('Help')}</div>
              <div className='close-cross' 
                  onClick={() => setTimeout(() => menuOpenHandler(false),2)}>+</div>

              <div styleName='menu_btns'>
                    <Button text={transS('Brief Tutorial')} mod='blue margin0' callback={() => setPopupOpen(1)} />
                    <Button text={transS('5 min Video Overview')} mod='blue margin0 aleft' callback={() => setPopupOpen(2)} />
                    <div>
                      <a href="mailto:support@contract.one"><Button text={transS('Email Support')} mod='blue margin0' /></a>
                    </div>
                    {/*
                    <div styleName='line'></div>
                    <Button text={<span>{transS('Create')} <span className="color_orange">Automated NDA</span></span>} mod='blue margin0' callback={() => dispatch(newDemo(1))} />
                    */}
                    <Button text={<span>{transS('Add')} <span className="color_lightRed">{transS('Demo')}</span> {transS('Project')}</span>} mod='blue margin0' callback={() => dispatch(newDemo(2))} />
              </div>
            </div>
            : (firstTime === 1) && <Tippp 
              align="1"
              text="You can create a demo <br />project or an automated NDA <br />from here"
              btn={
              {text:transS('Got it'),
               arrow:'bottom left',
               callback:() => {
                   dispatch({type: userConstants.DROP_FIRST_TIME, kind: 0});
                   
                   } 
              }}
             />}
        </div> 
    );
};

export default CSSModules(HelpMenu, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});
