export const TURN_INSERT_MODE = 'TURN_INSERT_MODE';
export const TURN_FORMATTING = 'TURN_FORMATTING'
export const SET_PARAGRAPH_STYLES = 'SET_PARAGRAPH_STYLES';
export const SET_PREVIOUS_COLOR='SET_PREVIOUS_COLOR'
export const TURN_COMMUNICATION_VIS = 'COMMUNICATION_VIS';

export const UPDATE_EDITING_PARAGRAPH = 'UPDATE_EDITING_PARAGRAPH';
export const UPDATE_EDITING_PARAGRAPH_SUCCESS = 'UPDATE_EDITING_PARAGRAPH_SUCCESS';
export const UPDATE_EDITING_PARAGRAPH_FAILED = 'UPDATE_EDITING_PARAGRAPH_FAILED';

export const BLOCK_PARAGRAPHS = 'BLOCK_PARAGRAPHS';

export const SET_POPUP_MODE = 'SET_POPUP_MODE';

// Для открытия окна в Colors
export const SET_IS_COLORS_OPENED = 'SET_IS_COLORS_OPENED'