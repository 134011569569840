import React, { Component } from 'react';
import cx from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './versionDownloadDropDown.css';
import DownloadIconDoc from '../../../../images/Updates.svg';
import Spinner from "../../../../components/spinner/Spinner"
import { transS } from '../../../../services/helpers/lang';
import DownloadingButton from '../topMenu/elements/DownloadingButton/DownloadingButton';

@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class VersionDownloadDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isWaitDownload: false
    }
  }

  _open = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };
  
  _func = async (format) => {
    const {download, version, subVersion, isHistory} = this.props;
    this.setState({isWaitDownload: true});
    download(format, version, subVersion, isHistory, 
      () => this.setState({isWaitDownload: false}))
  }

  /**
   * Add ability to close dropdown clicking outside
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Removing unnecessary event listener
   */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Close dropdown if we clicked outside
   * @param {Event} event - Click on the document
   */
  handleClickOutside = event => {
    if (this.dropdown && !this.dropdown.contains(event.target)) { // && event.target.tagName !== 'BUTTON' && event.target.tagName !== 'SPAN') {
      this.setState({
        isOpen: false
      });
    }
  }

  render() {
    const { isOpen, isWaitDownload } = this.state;
    let {_message, isPlanar} = this.props;
    let message = _message;
    let isExportForbidden = !!message;

    return (
      <div
        styleName={cx('wrapper', { '_open': isOpen })}
      >
        { isPlanar ?
            isWaitDownload ?
                <div styleName='spinner'><Spinner withoutLogo={true} />{transS('Exporting')}...</div>
                :
          //   <span styleName='planar' onClick={() => {
          // this._func('docx')} }>{transS('Export in .docx')}</span>        
              <DownloadingButton callback={() => {this._func('docx')}}/>
         : <svg 
           onClick={this._open}
           styleName='icon' viewBox={DownloadIconDoc.viewBox} 
           dangerouslySetInnerHTML={{ __html: `<use xlink:href="#${DownloadIconDoc.id}"></use>` }}/>
        }
        {
          isOpen &&
          <div
            styleName='drop-down'
            ref={dropdown => { this.dropdown = dropdown; }}
          >
            
            <div styleName='drop-down-inner'>
              {isExportForbidden ?
                <div styleName='drop-down-item' onClick={this._open} >{message}
                </div>
              :
              <button styleName='drop-down-item' type='button' onClick={()=>{this._func('pdf')}} >
                <svg width="25" height="25" viewBox="0 0 25 25">
                <path fill="#157EFB" fill-rule="evenodd" d="M5.128 4h14.744c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v6.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 0 1-1.512 1.512c-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 0 1-1.512-1.512C.186 18.302 0 17.655 0 15.872V9.128c0-1.783.186-2.43.534-3.082a3.635 3.635 0 0 1 1.512-1.512C2.698 4.186 3.345 4 5.128 4zm1.94 12.144c1.488 0 2.568-1.116 2.568-3.048s-1.08-3.036-2.568-3.036a2.24 2.24 0 0 0-1.788.876v-.732H3.756v8.004H5.28v-2.952c.468.6 1.104.888 1.788.888zm-.48-1.356c-.492 0-1.056-.3-1.308-.66v-2.052c.252-.372.816-.66 1.308-.66.876 0 1.464.684 1.464 1.68 0 1.008-.588 1.692-1.464 1.692zM16.472 16V7.996h-1.536v2.952a2.232 2.232 0 0 0-1.788-.888c-1.476 0-2.568 1.152-2.568 3.048 0 1.932 1.104 3.036 2.568 3.036.696 0 1.332-.312 1.788-.888V16h1.536zm-2.856-1.212c-.864 0-1.464-.684-1.464-1.68 0-1.008.6-1.692 1.464-1.692.516 0 1.068.276 1.32.66v2.052c-.252.384-.804.66-1.32.66zM20.056 16v-4.464h1.176v-1.332h-1.176v-.312c0-.54.3-.816.72-.816.276 0 .48.084.636.24l.576-.9c-.372-.396-.96-.54-1.512-.54-1.164 0-1.956.78-1.956 2.016v.312h-.96v1.332h.96V16h1.536z"/>
                </svg>
                Export as PDF
              </button>
              }
            </div>
            {
            !isExportForbidden &&
            <div styleName='drop-down-inner' onClick={()=>{this._func('docx')}}>
              <button styleName='drop-down-item' type='button' >
                <svg width="25" height="25" viewBox="0 0 25 25">
                  <path fill="#157EFB" fill-rule="evenodd" d="M5.128 4h14.744c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v6.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 0 1-1.512 1.512c-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 0 1-1.512-1.512C.186 18.302 0 17.655 0 15.872V9.128c0-1.783.186-2.43.534-3.082a3.635 3.635 0 0 1 1.512-1.512C2.698 4.186 3.345 4 5.128 4zm3.148 12V7.996H6.74v2.952a2.232 2.232 0 0 0-1.788-.888c-1.476 0-2.568 1.152-2.568 3.048 0 1.932 1.104 3.036 2.568 3.036.696 0 1.332-.312 1.788-.888V16h1.536zM5.42 14.788c-.864 0-1.464-.684-1.464-1.68 0-1.008.6-1.692 1.464-1.692.516 0 1.068.276 1.32.66v2.052c-.252.384-.804.66-1.32.66zm7.256 1.356c1.92 0 3.072-1.392 3.072-3.048 0-1.644-1.152-3.036-3.072-3.036-1.908 0-3.06 1.392-3.06 3.036 0 1.656 1.152 3.048 3.06 3.048zm0-1.356c-.948 0-1.476-.78-1.476-1.692 0-.9.528-1.68 1.476-1.68s1.488.78 1.488 1.68c0 .912-.54 1.692-1.488 1.692zm7.1 1.356c1.188 0 1.908-.516 2.292-1.056l-.996-.924a1.463 1.463 0 0 1-1.224.624c-.924 0-1.572-.684-1.572-1.692s.648-1.68 1.572-1.68c.528 0 .948.216 1.224.624l.996-.936c-.384-.528-1.104-1.044-2.292-1.044-1.788 0-3.072 1.26-3.072 3.036 0 1.788 1.284 3.048 3.072 3.048z"/>
                </svg>
                Export as DOC</button>
            </div>
            }
          </div>
        }
      </div>
    );
  }
}
