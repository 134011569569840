import CSSModules from 'react-css-modules'
import cx from 'classnames'
import React from 'react'
import styles from './finalize.css'
import Button from '../../../../../components/button/Button'
import { transF, transS } from '../../../../../services/helpers/lang'
import DownloadingButton from '../elements/DownloadingButton/DownloadingButton'

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})

export class FinalizeCancelInternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowPopupNew: false
        }
    }

    componentDidMount() {
        window.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (e) => {
        const {refPopupNew, refPopupBtnNew} = this
        const {isShowPopupNew} = this.state

        if (isShowPopupNew === true && refPopupNew && refPopupBtnNew &&
            refPopupBtnNew !== e.target && !refPopupBtnNew.contains(e.target) &&
            e.target !== refPopupNew && !refPopupNew.contains(e.target)) {
            this.setState({isShowPopupNew: false});
        }
    }

    render() {
        const {cancelRequest, download, copyDocument} = this.props
        const {isShowPopupNew, copyDocumentBlocked} = this.state

        return (
          <>
            <div styleName="wrapper">
                <div styleName='text_red' dangerouslySetInnerHTML={{__html: 
                  transF('reqFin')(0)}} /> 
                <Button text={<span dangerouslySetInnerHTML={{__html: 
                        transS('Cancel <br/> this request')}} />} mod='blue nowrap'
                    style={ {marginRight: '40px', textAlign: 'left'} }
                    callback={cancelRequest}/>
                {/* <Button text={transS('Export in .docx')} mod='blue nowrap margin0'
                    callback={() => download('docx')}/> */}
                <DownloadingButton callback={() => download('docx')}/>
            </div>
                <span styleName="margin40"/>
                <div className="borderHeader"></div>
                <div styleName='btnNewBlock'>
                    <div ref={e => this.refPopupBtnNew = e}>
                        <Button text={transS('New')} mod={`blue leftArrow${isShowPopupNew ? ' leftArrow_open' : ''}`}
                                style={{zIndex: '6'}}
                                callback={() => this.setState({isShowPopupNew: !isShowPopupNew})}/>
                    </div>
                    {isShowPopupNew && <div styleName='screen'></div>}
                    <div ref={e => this.refPopupNew = e}
                         styleName={cx('btnNewPopup', {'btnNewPopup_visible': isShowPopupNew})}>
                        {copyDocumentBlocked ? <Button text={transS('Copy as New Document')} mod='blue disabled'/>
                            : <Button text={transS('Copy as New Document')} mod='blue' callback={copyDocument}/>}
                    </div>
                </div>
          </>
        )
    }
}