import { paragraphConstants } from '../constants';
import * as actionTypes from '../../redux/paragraph/constants';
import store from '../../services/store';

const initialState = {
  paragraphList: '',
  commentList: '',
  changesList: '',
  paraTitles:null,
  commentedId: null,
  editedId: null,
  content: null,
  listReady: false,
  frameCallback: null,
  frameProgress: null,
  paragraphToFocus: null,
  displayAsNew: false,
  splitting: false,
  tempBlock: false,
  tasksCount: 0,
  stateMenu: {radioBtn: 0, textNarrowMode: true},
  popupMode: false,
  jumpChanges: {vis: false, end: 1},
  canPaste: true,
  noticeBanner: null,
  communicationVis: 0,
  previousColor: 0,
  isInch: false,
  paragraphStyles: null,
  isColorsOpened: false,
};

export default (state = initialState, action) => {
  let commentList = [], count, id, cList = '', pList = {};

  switch (action.type) {
  case paragraphConstants.GET_PARAGRAPH_LIST:
    return {
      ...state,
      listReady: false
    };
  case paragraphConstants.GET_PARAGRAPH_LIST_SUCCESS:
    //count tasks
    count = 0;
    id = `data-due-id=\\"${store.getState().userReducer.user._id}\\"`;
    
    if (action.data.commentList && action.data.commentList.length) action.data.commentList.split('\n').forEach(el =>{
        if (el.includes(id)) count++;
    });
    if (action.data.content) {
      cList = action.data.content.split('<p id="').reduce((ac, el)=>{
         let pid = el.match(/^\d[^"]*/g), founded = '', founded1='';
         if (pid) {
           /*founded = [...el.matchAll(/data-id="([^-]+)-(\d*|descr=)"/g)]
           .reduce((a,b)=> { let cp = (b[2] === 'descr=');
             let ctime = cp ? 1963 : b[2];
             return a.includes(`{"time": ${ctime}`) ? a 
            :( a + `{"time": ${ctime}, "userId": "${cp ? 0 : b[1]}", "id": "${pid}"}\n`)},'\n')
           .slice(1);*/
           founded1 = [...el.matchAll(/data-h="([^"]*)"/g)]
             .reduce((a,b) => a + ' ' + b[1], '')
             .slice(1).split(' ')
             .reduce( (a,b) => {
                let x = b.split('-');
                return (!x[1] || !x[3] || x[2]==='3' || x[2]==='4' || a.includes(x[1]) ) ? a 
                 : (a + `{"time": ${x[1]}, "userId": "${x[3]}", "id": "${pid}"}\n` )
             },'\n')
             .slice(1);
           if (founded || founded1)
           pList[pid] = el.replace(/<del [^>]*>/g,'\n')
             .replace(/<\/del>/g,'\t')
             .replace(/<[^>]+>/g,'')
             .replace(/^[^>]+>/g,'')
             .replace(/\n/g,'<del>')
             .replace(/\t/g,'</del>')
             .replace(/((?:\S*.){5})(.*)/,'$1...');
         }
         return ac + founded + founded1;
      },'')
    }
    //.public ? paragraphListResp.data.public : [],
        //  paragraphListResp.data.private ? paragraphListResp.data.private : [],
        //  paragraphListResp.data.content ? paragraphListResp.data.content : paragraphListResp.data,
        //  paragraphListResp.data.locks ? paragraphListResp.data.locks : null
        // ));
    return {
      ...state,
      paragraphList: action.data.public ? action.data.public : [],
      commentList: action.data.private ? action.data.private : [],
      content: action.data.content ? action.data.content : action.data,
      listReady: true,
      paraTitles:pList,
      changesList: cList,
      paragraphToFocus: action.data.locks,
      tasksCount: count,
      communicationVis: 0,
      redos: action.data.redos
    };
  case paragraphConstants.UPDATE_PARAGRAPH_LIST_IO:
    if (action.paragraph) 
       return {...state, 
         paragraphList: state.paragraphList + (state.paragraphList ? '\n' : '') + JSON.stringify(action.paragraph)};
    if (action.comment) {
       id = store.getState().userReducer.user._id;
       count = action.comment.content.includes(`data-due-id="${id}"`) ? 1 : 0;
       if (action.comment.closedMentions && action.comment.userId === id) 
         count -= action.comment.closedMentions.split(',').length;
       return {...state, 
         commentList: state.commentList + (state.commentList ? '\n' :  '') + JSON.stringify(action.comment),
         tasksCount: state.tasksCount + count };
    }
    if ( (state.paraTitles[action.data.id] === undefined) && action.data.paragraph)
        state.paraTitles[action.data.id] = action.data.paragraph[1].replace(/<del [^>]*>/g,'\n')
             .replace(/<\/del>/g,'\t')
             .replace(/<[^>]+>/g,'')
             .replace(/^[^>]+>/g,'')
             .replace(/\n/g,'<del>')
             .replace(/\t/g,'</del>')
             .replace(/((?:\S*.){5})(.*)/,'$1...');        
    return {...state, 
         changesList: state.changesList + (state.changesList ? '\n' : '') + action.change };  

  case paragraphConstants.RESET_PARAGRAPHS:
    return {
      ...state,
      paragraphList: [],
      frameCallback: null,
      frameProgress: null,
      listReady: false,
      commentList: [],
      content: null,
      paragraphToFocus: null,
      stateMenu: {radioBtn: 2, showChanges: 0} //radioBtn - режим показа комментариев textNarrowMode - режим показа текста
                               //showChanges 0(все) 1(last 1) 2(last 2) 3 none
                               //showNew - только нечитанные параграфы
                               //filterParagraphs - параграфы с изменениями
                               //changesCount - количество изменений
                               //hadChanges - были ли изменения на входе
                               //showNewer - команда на обновление dochanges 
                               // redline - full redline
    };
    
  case paragraphConstants.SET_MENU:
    if (state.frameCallback)
        state.frameCallback({c1:'setMenu', action: action.changedItem})
    return {
      ...state,
      stateMenu: {...state.stateMenu, ...action.changedItem}
    };
    
  case paragraphConstants.SET_FRAME:
    return {
      ...state,
      frameCallback: action.func,
      frameProgress: action.struc
    };
    
  case actionTypes.TURN_INSERT_MODE:
    return {
      ...state,
      insertOn: !!action.insertOn,
      canPaste: action.canPaste
    };
  case actionTypes.TURN_FORMATTING:
    return {
      ...state,
      formatting: action.formatting
    }
  case actionTypes.SET_PARAGRAPH_STYLES:
    return {
      ...state,
      paragraphStyles: action.paragraphStyles
    }
  case actionTypes.SET_IS_COLORS_OPENED:
    return {
      ...state,
      isColorsOpened: action.isColorsOpened
    }
  case actionTypes.SET_PREVIOUS_COLOR:
    return {
      ...state,
      previousColor: action.previousColor
    }
  case actionTypes.TURN_COMMUNICATION_VIS:
    return {
      ...state,
      communicationVis: action.communicationVis
    };
  case actionTypes.UPDATE_EDITING_PARAGRAPH:
    return {
      ...state,
      tempBlock: !action.timerUpdate
    };
  case actionTypes.BLOCK_PARAGRAPHS:
    return {
      ...state,
      tempBlock: action.block
    };
  case actionTypes.SET_POPUP_MODE:
      return {
        ...state,
        popupMode: action.popupMode
      }
  case paragraphConstants.SET_JUMP_CHANGES_END:
    return {...state, jumpChanges: {...state.jumpChanges, ...action.payload}}
  case paragraphConstants.UPDATE_COMMENT_LIST:
    return {...state, commentList: action.commentList}
  case paragraphConstants.SET_TASKS_COUNT:
    return {...state, tasksCount: action.tasksCount}
  case paragraphConstants.SET_NOTICE_BANNER_CONTENT:
    return {...state, noticeBanner: action.data}
  default:
    return state;
  }
};
