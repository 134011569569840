import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './firstTime.css';
import { roles } from '../../../../../configs/appConfig';
import {userConstants} from '../../../../services/constants';
import {PlusSign} from '../../../../components/plusSign/PlusSign';
import {getInvitePopupAction} from '../../../../services/actions/projectActions';
import {block5PermissionCheck} from '../../../../../common/validation';
import {showPaymentAction} from '../../../../services/actions/userActions';
import {newDemo} from '../../../../redux/document/actions';
import {getPopUpAction} from '../../../../services/actions/dataActions';
import {showPopupManyFiles, universalDrop} from "../../../../services/helpers"
import HelpPopup from "../help/HelpPopup";
import HelpMenu from "../help/HelpMenu";
import { transL, transS } from '../../../../services/helpers/lang';

const FirstTime = ({isSmall, forbidFiles})  => {
  const {user} = useSelector(state => state.userReducer);
  const cleanUp = () => {
     dispatch({type: userConstants.DROP_FIRST_TIME, kind: 1}); 
  };
  const goCreateProject = () => {
     dispatch(getInvitePopupAction(true, null));
  }
  const dispatch = useDispatch();
  const small = isSmall ? ' small' : '';
  const [compact, setCompact] = useState(true);
  const [overDropZone, setIsOverDropZone] = useState(false)
  const [showHelp, setShowHelp] = useState(0);
  const dropFiles = e => {
      e.stopPropagation();
      e.preventDefault();
      const infiles = e.dataTransfer.files;
      if (isSmall || !infiles || !infiles.length) return;
      if ((user.ticks <= 0) && block5PermissionCheck(infiles.length)) {
         dispatch(getPopUpAction(showPopupManyFiles(getPopUpAction, showPaymentAction)));
         return 
      }
      universalDrop(infiles, (files, names) => {
        dispatch(getInvitePopupAction(true, null, false, {files, names}));
      });
  };
  
  const newProject = () => (
    <div styleName={`tile project${forbidFiles ? " semi" :" clickable"}${
       isSmall ? small :' newProject'}`} 
         onDrop={(e) => !forbidFiles && dropFiles(e)}
         onDragEnter={() => !forbidFiles && setIsOverDropZone(true)}
         onDragLeave={() => !forbidFiles && setIsOverDropZone(false)} 
         data-strokeplus={overDropZone ? "1" : ""}
         onDragOver={(e) => { e.stopPropagation();
             if (!forbidFiles) setIsOverDropZone(true);
            e.preventDefault();}}
         onClick={() => !forbidFiles && goCreateProject()}>
        <div styleName="subTitle withMargin">
            <span className="color_primary">{transS('Start a new project')}</span>
        </div>
        <span styleName="subText"
          dangerouslySetInnerHTML={{__html: transL('Start with your doc')}} />
      {!isSmall && <PlusSign margin="70px" />}
      {!isSmall && <div styleName="uploadDoc">{transS('Upload a document')}</div>}
      {forbidFiles && <div styleName='hint'>
        {transL('5less')}
        <div onClick={() => dispatch(showPaymentAction(true))}>{transS('Upgrade Now!')}</div>
      </div>}
    </div>      
  );
  const nda = () => (
       <div styleName={`tile ndaa${small}`} onClick={() => null /*dispatch(newDemo(1))*/}>
         <div styleName="subTitle withMargin"
          dangerouslySetInnerHTML={{__html: transL('Create an automated NDA')}} />
         <span dangerouslySetInnerHTML={{__html: transL('Set up a simple NDA')}} />
       </div> 
  );
  const demo = () => (
       <div styleName={`tile clickable${small}`} onClick={() => dispatch(newDemo(2))}>
         <div styleName="subTitle withMargin"
           dangerouslySetInnerHTML={{__html: transL('add demo')}} />           
         {transL('see demo')}
       </div> 
  );

  return isSmall ?
    <div styleName="wrapper small">
        <div style={{display:"flex", alignItems:"center"}}>
          <div styleName="subTitle">{transL('New to C1?')}</div>
          <span>&nbsp;&nbsp;</span>
          <div onClick={() => setCompact(!compact)} styleName="clickable">
          {compact ? <span styleName="getstarted">{transS('Get Started')}</span> : <span styleName="arrowShift" className="arrow arrowOpen"></span>}
          </div>  
          <div className='close-cross' 
               onClick={()=>cleanUp()}>+
          </div>
        </div>
        {!compact && <div style={{display:"flex", marginTop:"40px", justifyContent:"space-between"}}>
          {newProject()}
          {nda()}
          {demo()}
          <div onClick={e => {e.preventDefault; e.stopPropagation(0); setShowHelp(1)}} 
              styleName="tile clickable help">
              <div styleName="subTitle">{transS('Help')}</div>
              <div className="helpBtn helpBtnBlue">?</div>
              <HelpMenu showHelp={showHelp} helpClose={() => setShowHelp(0)} />
          </div>
          
        </div>}
    </div>
    : (<div styleName="wrapper">
        <div>Hi {user.firstname}!</div>
        <div>Happy to have you onboard!</div>
        <div styleName="title">{transS('Let’s Get Started!')}</div>
        <div styleName="horisontal">
            {newProject()}
            <div style={{display:"flex", flexDirection: "column", justifyContent: "space-between"}}>
               {nda()}
               {demo()} 
            </div>    
        </div>
        <div styleName="horisontal">
            <div styleName="opaque tile">
              <div styleName="subTitle withMargin">What is Contract.one</div>
              It’s a platform that brings a perfect experience to every stage of contracting: document automation, legal collaboration, negotiations and contract execution.
            </div>
            <div styleName="tile">
              <div styleName="subTitle withMargin">{transS('Take a quick tour')}</div>
              <div styleName="clickable" className="color_primary marginb10"
                onClick={() => setShowHelp(1)}>{transS('Brief Tutorial')}</div>
              <div styleName="clickable" className="color_primary"
                onClick={() => setShowHelp(2)}>5-min Overview Video</div>              
            </div>    
        </div>
       {!!showHelp && <HelpPopup popupOpen={showHelp} popupOpenHandler={setShowHelp} />}
    </div>
  );
    
}

export default CSSModules(FirstTime, styles, {allowMultiple: true});

